.App {
  height: 100vh;
  background-color: #efefef;
}

.App-header {
  background-color: #fff;
  font-size: 24px;
  padding: 16px;
}

.App-body {
  display: flex;
  flex-wrap: wrap;
}

.App-card {
  background-color: #fff;
  margin: 16px;
  padding: 8px;
}
